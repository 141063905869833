import React, {useEffect, useState} from 'react';
import TaskModel from "../../../../../models/responses/task.model";
import styles from '../GanttChart.module.scss';
import {Input, Tab, TabPane} from 'semantic-ui-react'
import {TaskType} from "../../../../../models/task-type";
import {useTypedSelector} from "../../../../../store/selectors/selectors.utils";
import WbsTree from "../../WbsTree/WbsTree";
import {
    useActiveProjectCalendarsSelector,
    useActiveProjectSelector,
    useCpmMapSelector
} from "../../../../../store/selectors/project.selectors";
import {CpmTaskModel} from "../../../../../models/responses/cpm-task.model";
import TaskStatusModel from "../../../../../models/responses/task-status.model";
import {convertDateToIndex} from "../../../../../utils/cpm-functions/cpm-app/functions/handleEvent";

export default function TaskInfo(props: {task: TaskModel}) {
    const task = props.task;
    const wbsList = useTypedSelector((state) => state.wbs.wbsList)
    const [openWbsTree, setOpenWbsTree] = useState(false);
    const [wbsPlaceholder, setWbsPlaceholder] = useState(task.wbsPath === '' ? task.wbs : task.wbsPath + ' / ' + task.wbs);
    const projectCalendars = useActiveProjectCalendarsSelector();
    const cpmMap = useCpmMapSelector();
    const cpmTask: CpmTaskModel = cpmMap.get(task.task_id);
    const workingHoursPerDay = projectCalendars.get(`whd:${cpmTask.cal_id}`) || 8;
    const dataDateIndex = convertDateToIndex(Math.floor(new Date().getTime() / 86400000) * 86400, cpmTask.cal_id, projectCalendars);
    const activeProject = useActiveProjectSelector();
    const preds = cpmMap.get(`${task.task_id}:preds`);
    const succs = cpmMap.get(`${task.task_id}:succs`);

    const taskTypeConverter = (taskType: TaskType) => {
        switch (taskType) {
            case TaskType.TT_TASK:
                return "Task Dependent";
            case TaskType.TT_MILE:
                return "Start Milestone";
            case TaskType.TT_FIN_MILE:
                return "Finish Milestone";
            case TaskType.TT_RSRC:
                return "Resource Dependent";
            case TaskType.TT_LOE:
                return "Level of Effort";
            default:
                return "Task Dependent";
        }
    }

    useEffect(() => {
        setWbsPlaceholder(task.wbsPath === '' ? task.wbs : task.wbsPath + ' / ' + task.wbs);
    }, [task]);

    const panes = [
        { menuItem: 'Details', render: () =>
                <TabPane>
                    <div>
                        <div className={styles.TaskDetailRow}>
                            <span className={styles.TaskDetailSmall}>
                                <label>Task Type:</label>
                                <Input
                                    type="text" value={taskTypeConverter(task.task_type)} readOnly/>
                            </span>
                            <span className={styles.TaskDetailSmall}>
                                <label>Task Code:</label>
                                <Input
                                    type="text" value={task.task_code}/>
                            </span>
                            <span className={styles.TaskDetail}>
                                <label>Task Name:</label>
                                <Input
                                    type="text" value={task.task_name}/>
                            </span>
                        </div>
                        <div className={styles.TaskDetailRow}>
                            <span className={styles.TaskDetail}>
                                <label>WBS:</label>
                                <Input
                                    type="text" value={wbsPlaceholder} readOnly
                                    onClick={() => setOpenWbsTree(true)}
                                />
                            </span>
                        </div>
                        <div className={styles.TaskDetailRow}>
                            <span className={styles.TaskDetailSmall}>
                                <label>Original Duration:</label>
                                <Input
                                    type="text" value={`${task.targetDuration / (workingHoursPerDay * 2)}d`} readOnly
                                />
                            </span>
                            <span className={styles.TaskDetailSmall}>
                                <label>Remaining Duration:</label>
                                <Input
                                    type="text" value={`${cpmTask.duration / (workingHoursPerDay * 2)}d`} readOnly
                                />
                            </span>
                            <span className={styles.TaskDetailSmall}>
                                <label>Actual Duration:</label>
                                <Input
                                    type="text" value={task.status === TaskStatusModel.COMPLETE || task.status === TaskStatusModel.DECLARED_COMPLETE ?
                                    // @ts-ignore
                                    `${Math.ceil((cpmTask.ef - cpmTask.es) / (workingHoursPerDay * 2))}d` :
                                    task.status === TaskStatusModel.IN_PROGRESS || task.status === TaskStatusModel.SUSPENDED ?
                                        // @ts-ignore
                                        `${Math.ceil((dataDateIndex - cpmTask.es) / (workingHoursPerDay * 2))}d` :
                                        '0d'}
                                    readOnly
                                />
                            </span>
                            <span className={styles.TaskDetail}>
                                <label>Calendar:</label>
                                <Input
                                    type="text" value={
                                    activeProject!.calendars.find((cal: any) => cal.uId === cpmTask.cal_id).calendar_name ||
                                    "No calendar found"} readOnly
                                />
                            </span>
                        </div>
                    </div>
                </TabPane>
        },

        {
            menuItem: 'Relationships', render: () =>
                <TabPane>
                    <div>
                        <div className={styles.TaskRelationshipsContainer}>
                            <span className={styles.TaskDetail}>
                                <label>Predecessors:</label>
                                <div className={styles.RelationshipListContainer}>
                                <table className={styles.RelationshipList}>
                                    <th>
                                        <tr className={styles.RelationshipRow}>
                                            <td className={styles.RelationshipHeaderCellSmall}>Driving</td>
                                            <td className={styles.RelationshipHeaderCellSmall}>Critical</td>
                                            <td className={styles.RelationshipHeaderCellSmall}>Task ID</td>
                                            <td className={styles.RelationshipHeaderCell}>Task Name</td>
                                            <td className={styles.RelationshipHeaderCellSmall}>Type</td>
                                            <td className={styles.RelationshipHeaderCellSmall}>Lag</td>
                                        </tr>
                                    </th>
                                    <tbody>
                                    {preds.map((pred: any) => {
                                        const link = cpmMap.get(pred);
                                        const task = cpmMap.get(link.pred_task_id);
                                        return (
                                            <tr className={styles.RelationshipRow}>
                                                <td className={styles.RelationshipCellSmall}>{link.driving}</td>
                                                <td className={styles.RelationshipCellSmall}>{link.critical}</td>
                                                <td className={styles.RelationshipCellSmall}>{task.task_code}</td>
                                                <td className={styles.RelationshipCell}>{task.task_name}</td>
                                                <td className={styles.RelationshipCellSmall}>{link.pred_type}</td>
                                                <td className={styles.RelationshipCellSmall}>{link.lag}</td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </table>
                                </div>

                            </span>
                            <span className={styles.TaskDetail}>
                                <label>Successors:</label>
                            </span>
                        </div>
                    </div>
                </TabPane>
        },
    ]

    return (
        <div className={styles.TaskInfoContainer}>
            <Tab panes={panes} />
            {openWbsTree && <WbsTree
                setOpenWbsTree={setOpenWbsTree}
                taskId={task.task_id}
                existingWbs={task.wbs_id}
                setWbsPlaceholder={setWbsPlaceholder}
            />}
        </div>
    )
}